import { Component, OnDestroy, HostListener } from '@angular/core';

import { Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { ParentComponent } from './parent/parent.component';
import { RestProviderService } from './services/rest-provider.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedDataService } from './services/shared-data.service';
import { Subscription } from 'rxjs';
//import { Firebase } from '@ionic-native/firebase/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Device } from '@ionic-native/device/ngx';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { EventsService } from './services/events-service';
import { AndroidFullScreen } from '@ionic-native/android-full-screen';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { DBProviderService } from './services/db-provider.service';
import { InitPage } from './init/init.page';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent extends ParentComponent implements OnDestroy {

  lastPause: number;
  onResumeSubscription: Subscription;
  onPauseSubscription: Subscription;

  pausedOn: Date = new Date();
  titleMessage:string;
  textMessage:string;
  textYesButton:string;
  textNotButton:string;
  ticketGUID : string;
  displayApproveLogin:boolean=false;
  _window=window;

  constructor(
    public ons:OpenNativeSettings,
    private faio: FingerprintAIO,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private keyboard: Keyboard,
    public restProvider: RestProviderService,
    public events: EventsService,
    //private firebase: Firebase,
    private firebase: FirebaseX,
    private badge: Badge,
    public domSanitizer: DomSanitizer,
    public sharedData: SharedDataService,
    public device: Device,
    public screenOrientation: ScreenOrientation,
    public http: HttpClient,
    public dbProvider: DBProviderService
  ) {
    super(restProvider,events,domSanitizer, sharedData, device, screenOrientation);
    this.initializeApp();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => {
      this.setBodyClass();
    }, 250);
  }

  private setBodyClass(){
    if(this.isMobileMode())
    {
      document.body.classList.add('mobile-mode-app');
    }else{
      document.body.classList.remove('mobile-mode-app');
    }
  }

  public enableImmersiveMode():void{
    if(this.platform.is('android'))
    {
      AndroidFullScreen.isImmersiveModeSupported()
      .then(() =>{
        console.log('Immersive mode supported');
        AndroidFullScreen.immersiveMode()
        .then(() =>
         console.log('Enabled Immersive Mode')
         )
        .catch(err => console.log(err));
      }).catch(
        err => console.log(err)
      );
    }
  }

  initializeApp() {
    this.setBodyClass();
    this.platform.ready().then(() => {
      if(this.isMobile()){
        this.statusBar.hide();
        this.enableImmersiveMode();
      }
      if (this.isMobile() && this.keyboard) {
        //this.keyboard.disableScroll(true); TODO
        //this.keyboard.hideKeyboardAccessoryBar(false);TODO
        this.keyboard.hideFormAccessoryBar(false);
      }

      if (this.isMobile() && this.splashScreen) {
        this.splashScreen.hide();
      }

      if (this.isMobile()) {
        this.deleteDownloadedFiles();
        this.firebase.onMessageReceived().subscribe(res => {
          setTimeout(() => {
            this.ticketGUID=res.ticketGUID;
            this.titleMessage=res.ApproveWebLoginTitle;
            this.textMessage=res.ApproveWebLoginBody;
            this.textYesButton=res.ApproveWebLoginBtnYes;
            this.textNotButton=res.ApproveWebLoginBtnNo;
            this.displayApproveLogin=true;
            this.showHiddenMessage();
          }, 0);
        });
        //this.firebase.onNotificationOpen().subscribe(res => {});
      }
    });

    this.onPauseSubscription = this.platform.pause.subscribe(() => {
      this.pausedOn = new Date();
    });

    this.onResumeSubscription = this.platform.resume.subscribe(() => {
      let currentDate: Date = new Date();
      if ((currentDate.getTime() - this.pausedOn.getTime()) > 21600000) {
        this.sharedData.plugins.clear();
      }  
      this.hideAllContextMenu();
      this.enableImmersiveMode();
      if(!this.sharedData.disableOnResumeSubscription)
      {
        if (this.isMobile() && !this.sharedData.displayUpdatingWindow)
        {
          this.configureDeploy();
          setTimeout(() => {
            this.checkAndDownloadUpdate();
          }, 100);
        }
        if(this.sharedData.openedNativeSettings)
        {
          this.executeOnResumeSubscription();
        }else{
          if ((currentDate.getTime() - this.pausedOn.getTime()) > 10000) {
            this.executeOnResumeSubscription();
          }  
        }
      }
    });
  }

  public acceptApproveLogin(){
    this.answerAprroveNotification(true);
  }
  
  public cancelApproveLogin(){
    this.answerAprroveNotification(false);
  }

  public tryAgainBiometricAuthentication(){
    if(this.sharedData.currentBiometricError.parentPage instanceof InitPage)
    {
      this.sharedData.currentBiometricError.parentPage.validateBiometricAuthenticationAccess(this.sharedData.currentBiometricError.lastSetting);
    }else{
      this.validateBiometricAuthenticationAccess();
    }
  }

  public enterAccessCode(){
    if(this.sharedData.currentBiometricError.parentPage instanceof InitPage)
    {
      this.sharedData.currentBiometricError.parentPage.validateBiometricAuthenticationAccess(this.sharedData.currentBiometricError.lastSetting);
    }else{
      this.validateBiometricAuthenticationAccess();
    }
  }

  public validateBiometricAuthenticationAccess():void{
    this.executeBiometricAuthentication(this.faio,this.platform).then(res => {
      this.refreshApp();
    }, error => {    
      console.error(error);
    });
  }

  private answerAprroveNotification(response:any){
    this.displayApproveLogin=false;
    let url = "https://discover.businesswideweb.net/httpHandler/AnswerAuthenticationValidate.ashx";
    //url="http://b473f571.ngrok.io/httpHandler/AnswerAuthenticationValidate.ashx";
    let headers = new HttpHeaders({ 'TicketGUID': this.ticketGUID?this.ticketGUID:''});

    let requestOptions: any = {
      headers: headers,
      responseType: 'text'
    };
    
    let params={allowLogin:response};
    this.http.post(url,params,requestOptions).timeout(30*1000).subscribe((response: any) => {
      console.log(response);
    },error=>{
      console.error(error);
    });
  }


  private refreshApp():void{
    this.pausedOn = new Date();
    this.restProvider.getUserPreferences()
    .then(result => {
      if (result.length > 0) {
        this.events.publish('home:reloadAll', { viewId: result[0]['view_id'], pluginId: result[0]['plugin_id'] });
      }
    }, error => {
      console.error(error);
      this.showMessage('Error', error);
    }).catch(ex => {
      console.error(ex);
      this.showMessage('Error', '', ex);
    });
  }

  private executeOnResumeSubscription(){
    this.verifyIfEnterApp(this.dbProvider,this.faio,this.platform).then(res => {
      this.getAppResources();
      this.refreshApp();
    }, error => {    
      console.error(error);
    });
  }

  async setBadgeNumber(badgeNumber: number) {
    try {
      let hasPermission = await this.badge.hasPermission();
      if (!hasPermission) {
        let permission = await this.badge.requestPermission();
        if (permission) {
          this.badge.set(badgeNumber);
        }
      } else {
        let isSupported = await this.badge.isSupported();
        if (isSupported) {
          this.badge.set(badgeNumber);
        } else {
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  private deleteDownloadedFiles(): void {
    //TODO
  }

  ngOnDestroy() {
    //always unsubscribe your subscriptions to prevent leaks
    this.onResumeSubscription.unsubscribe();
    this.onPauseSubscription.unsubscribe();
  }
}
